import React from "react"
import Layout from "../components/layouts/investments_layout"
import Meta from "../components/meta"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import { Link, graphql } from "gatsby"

import InvestmentOverview from "../components/investment_overview"

const Portfolio = ({ data }) => {
  return (
    <Layout
      logo="logo_svg_investments.svg"
    >
      <Meta title="Investments by threesixty Investments Ltd" description="Learn more about the threesixty Investments Ltd portfolio of past investments." />
      <Container className="pb-5">
        <Row>
          <Col>
            <h1>Our Investments</h1>
          </Col>
        </Row>
        {data.investments.edges.map(({ node }) => (
          <Row>
            <Col>
              <InvestmentOverview
                title={node.frontmatter.title}
                acquired={node.frontmatter.acquired}
                img={node.frontmatter.thumbnail}
                excerpt={node.excerpt}
                website={node.frontmatter.website}
                key={node.id}
                slug={'/portfolio' + node.fields.slug}
              >
                <Link
                  to={'/portfolio' + node.fields.slug}
                  className="btn text-investments-light btn-investments-light-accent"
                  role="button"
                >
                  Read More
                </Link>
              </InvestmentOverview>
            </Col>
          </Row>
        ))}
        <Row style={{ paddingTop: "6rem" }}>
          <Col>
            <h1>Past Investments</h1>
          </Col>
        </Row>
        {data.pastInvestments.edges.map(({ node }) => (
          <Row>
            <Col>

              <InvestmentOverview
                title={node.frontmatter.title}
                acquired={node.frontmatter.acquired}
                img={node.frontmatter.thumbnail}
                excerpt={node.excerpt}
                website={node.frontmatter.website}
                key={node.id}
                slug={'/portfolio' + node.fields.slug}
              >
                <Link
                  to={'/portfolio' + node.fields.slug}
                  className="btn text-investments-light btn-investments-light-accent"
                  role="button"
                >
                  Read More
                </Link>
              </InvestmentOverview>
            </Col>
          </Row>
        ))}
      </Container>
    </Layout>
  )
}
export default Portfolio;

export const query = graphql`
  query {
    investments: allMarkdownRemark(
      filter: {fields: {type: {eq: "investments" } } }
      sort: {fields: [frontmatter___date], order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 400, height: 250, quality: 90, layout: CONSTRAINED)
              }
            }
            summary
            acquired
            website
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
    pastInvestments: allMarkdownRemark(
      filter: {fields: {type: {eq: "past-investments" } } }
      sort: {fields: [frontmatter___date], order: ASC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 400, height: 250, quality: 90, layout: CONSTRAINED)
              }
            }
            summary
            acquired
            website
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
