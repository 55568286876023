import React from "react"

import { Link } from "gatsby"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { GatsbyImage } from "gatsby-plugin-image"


function InvestmentOverview(props) {
  return (
    <Row className="mt-4">
      <Col xs={12} md={4}>
        <Link to={props.slug}>
          <GatsbyImage image={props.img.childImageSharp.gatsbyImageData} alt={props.title} />
        </Link>
      </Col>
      <Col xs={12} md={8} className="my-4 my-md-0">
        {props.website
          ? <a className={props.titleClasses} href={props.website}><h4>{props.title}</h4></a>
          : <h4 className={props.titleClasses}>{props.title}</h4>
        }
        <h5 className={props.acquiredClasses}>{props.acquired}</h5>
        <p>{props.excerpt}</p>
        <p>{props.children}</p>
      </Col>
    </Row>
  )
}

InvestmentOverview.defaultProps = {
  titleClasses: "text-threesixty-orange pt-3 pt-md-0",
  acquiredClasses: "text-investments-dark"
}

export default InvestmentOverview
